import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Section, breakpoints } from '~styles/global'
import RichText from '~components/RichText'

const TextBlock = ({ content, className }) => {

const { mobile } = breakpoints

return (
    <Section 
      className={className} 
      css={css`
        background-color: var(--beige);
    `}>
      <div css={css`
        grid-column: span 12;
      `}>
        <div css={css`
          max-width: 645px;
          margin: 0 auto;
          ${mobile}{
            max-width: 100%;
          }
        `}>
            <RichText content={content} css={css`
              h2 {
                font-size: 60px;
                line-height: 1.3;
                font-family: 'Romie', sans-serif;
                margin-bottom: 0.7em;
                ${mobile}{
                  font-size: 36px;
                }
              }
              h3 {
                font-size: 14px;
                line-height: 1.3;
                text-transform: uppercase;
                margin-bottom: 0.5em;
                ${mobile}{
                  font-size: 12px;
                }
              }
              p {
                margin-bottom: 2rem;
              }
            `}></RichText>
        </div>
      </div>
    </Section>
)
}

TextBlock.propTypes = {
  content: PropTypes.array,
}

TextBlock.defaultProps = {
}

export default TextBlock
