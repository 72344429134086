import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { css } from '@emotion/react';
import Layout from "~components/Layout"
import Seo from "~components/Seo"
import Image from '~components/Image'
import { breakpoints, Section } from '~styles/global'
import ImageBlock from '~components/ImageBlock'
import TextBlock from '~components/TextBlock'
import CrinkleButton from "~components/CrinkleButton"
import ProductGrid from "~components/ProductGrid"

const Maker = ({ pageContext, data }) => {
  const { title, content, slug } = pageContext
  const { mobile } = breakpoints

  const allProducts = useStaticQuery(graphql`
  {
    allSanityProduct{
      nodes {
        id
        content {
          _rawMaker(resolveReferences: {maxDepth: 2})
          _rawVariants(resolveReferences: {maxDepth: 5})
        }
      }
    }
  }
  `)

  let moreProducts = allProducts.allSanityProduct.nodes.filter(prod => {
    return (
      prod?.content?._rawMaker?.slug.current === slug
    )
  }).map(p => p.content._rawVariants)

  moreProducts = moreProducts.flat()

  return (
    <>
      <Seo
      title={title}
      image={content?.headerImage?.asset?.url}
      description={""}
      />
      <Section>
        <h1 className={`serif`} css={css`
            grid-row: 1 / span 1;
            grid-column: span 13;
            text-align: center;
            margin-top: 8rem;
            margin-bottom: 6rem;
          `}>
          {title}
        </h1>
      </Section>
        <ImageBlock
          image={content?.headerImage}
          css={css`
            margin-bottom: 10rem;
            ${mobile} {
              margin-bottom: 8rem;
              display: none;
            }
          `}
        />
        <ImageBlock
          image={content?.headerImage}
          aspectRatio={1}
          css={css`
            margin-bottom: 10rem;
            display: none;
            ${mobile} {
              margin-bottom: 8rem;
              display: block;
            }
          `}
        />
        <TextBlock
          content={content?.text}
          css={css`
            margin-bottom: 10rem;
            text-align: center;
            ${mobile} {
              margin-bottom: 8rem;
            }
          `}
        />
        <div css={css`
          display: grid;
          justify-content: center;
          margin-bottom: 10rem;
          padding: 0 60px;
          ${mobile} {
            margin-bottom: 8rem;
          }
        `}>
          <Image
            asset={content?.profileImage}
            aspectRatio={1}
            css={css`
              max-width: 420px;
              border-radius: var(--standard);
              overflow: hidden;
              filter: grayscale(100%);
          `}/>
        </div>
        {moreProducts?.length > 0 && 
        <div css={css`
          padding-top: 11rem;
          background: var(--white);
          ${mobile}{
              padding-top: 8rem;
          }
        `}>

          <h2 
            className='h1 serif'
            css={css`
              text-align: center;
              margin-bottom: 7rem;
          `}>
            Products
          </h2>
          <ProductGrid products={moreProducts} />
        </div>
        }
        <CrinkleButton to="/makers" css={css`background: var(--beige);`}>
          All makers
        </CrinkleButton>
    </>
  )
}

export default Maker
